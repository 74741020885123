import React, { Component } from "react";
import Layout from "../../layout";
import { graphql } from "gatsby";
import HTMLContent from "../../components/htmlcontent";

class WorkPage extends Component {
  render() {
    return (
      <Layout>
        {<HTMLContent html={this.props.data.markdownRemark.html} />}
      </Layout>
    );
  }
}

export default WorkPage;

export const pageQuery = graphql`
  query WorkPageQuery {
    markdownRemark(frontmatter: { for: { eq: "work" } }) {
      html
    }
  }
`;
